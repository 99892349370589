import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

const CardContainer = styled.div`
    padding-top: 20px;
    font-family: ${theme.font};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    height: 450px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: black;
    font-size: 24px;
    drop-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    a{
        text-decoration: none;
    }
    &:hover {
        box-shadow:0 5px 10px #0000001a,0 2px 4px #00000026;
        }
    `;

const CarouselImage = styled.img`
    width: 80%;
    max-height: 40%;
    object-fit: cover;
    border-radius: 3%;
`;

const PricesContainer = styled.div`
    padding: 3%;
    padding-top: 10%;
    padding-bottom: 10%;
    display: flex;
    justify-content: space-around;
    width: 100%;
`;
const StyledTitle = styled.h1`
    color: ${theme.colors.brand};
    padding: 5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 0.8em;
    text-align: center;
    line-height: 1.5;
`;
const Styledh3 = styled.h3`
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.lightGreen};
`;
const Strikethrough = styled(Styledh3)`
    color: ${theme.colors.brand};
    background: linear-gradient(to left top, transparent 47.75%, currentColor 48%, currentColor 52%, transparent 52.25%);
`;
const FireSales = styled.span`
    color: #C73E00;
    font-size: 0.6em;
`;
const StyledRow = styled.div`
    padding-top: 3%;
    padding-bottom: 1%;
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    .mds-icon__arrow-forward{
        font-size: ${theme.fontSizes.medium};
        color: ${theme.colors.brand};
    }
`;

interface CardProps {
    image: string;
    title: string;
    initialPrice: number;
    finalPrice: number;
    soldAmount: number;
    link: string;
}
const CarouselCard : React.FC<CardProps> = ({image, title, initialPrice, finalPrice, soldAmount, link}) => {
    return (
        <a href={link} style={{ textDecoration: "none" }}>
        <CardContainer>
            <CarouselImage src={image} alt={title}/>
            <StyledTitle>{title}</StyledTitle>
            <PricesContainer>
            <Strikethrough>{initialPrice} €</Strikethrough>
            <Styledh3>{finalPrice} €</Styledh3>
            </PricesContainer>
            <StyledRow>
            <FireSales>
            <span className="mds-picto__flamme"></span>
            {soldAmount} vendus 
            </FireSales>
            <a href={link}>
            <span className="mds-icon__arrow-forward"></span>
            </a>
            </StyledRow>
        </CardContainer>
    </a>
    );
}
export default CarouselCard;