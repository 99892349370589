import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CounterButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.brand};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  background-color: white;
  font-color: ${theme.colors.brand};
  margin: 0 5px;
  user-select: none;
`;

const CounterDisplay = styled.input`
  width: 50%;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 0 10px;
`;

const Counter: React.FC = () => {
  const [count, setCount] = useState<number>(0);

  const increment = () => setCount(count + 1);
  const decrement = () => setCount(Math.max(count - 1,0));
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setCount(value);
    }
  };

  return (
    <CounterContainer>
      <CounterButton onClick={decrement}>-</CounterButton>
      <CounterDisplay
        type="text"
        value={count}
        onChange={handleInputChange}
      />
      <CounterButton onClick={increment}>+</CounterButton>
    </CounterContainer>
  );
}

export default Counter;
