import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../components/userContext/userContext";
import React from "react";
import TagChoiceTop from "../components/onboard/tagsChoiceTop";
import TagChoiceMiddle from "../components/onboard/tagsChoiceMiddle";
import TagChoiceBottom from "../components/onboard/tagsChoiceBottom";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

async function fetchData(url: string) {
  const response = await axios.get(`${apiUrl}/${url}`);
  console.log(response.data);
  return response.data;
}

const OnBoardPage: React.FC = () => {
  const availableTags1: string[] = ["Monument", "Château", "Abbaye", "Musée", "Cathédrale", "Plage", "Jardin", "Zoo", "Historique", "Architecture", "Patrimoine", "Tourisme culturel", "Art"]
  const availableTags2: string[] = ["Famille", "Romantique", "Aventure", "Vacances", "Découverte", "Loisirs", "Enfants", "Adultes", "Ado", "Couples", "Groupes", "Solitaire"]
  const availableTags3: string[] = ["Sport","Nature", "Randonnée", "Montagne","Concert", "Festival", "Théâtre", "Spectacle", "Conférence", "Salon", "Exposition", "Cinéma", "Comédie", "Opéra", "Ballet", "Cirque", "Escape game"]
  const availableTags4: string[] = ["Rock", "Pop", "Jazz", "Classique", "Électro", "Folk", "Reggae", "Blues", "Country"]
  const availableTags5: string[] = ["Weekend", "Semaine", "Soirée", "Matinée","Nouveauté", "Tendance", "Halloween", "Noël", "Carnaval","France"]

  const [pageLoad, setPageLoad] = useState<string[] | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const context = useContext(UserContext);
  if (!context) {
    throw new Error('HomePage must be used within a UserProvider');
  }
  const userId = context.currentUser;

  useEffect(() => {
    fetchData(`personal/tags/${userId}`).then((data) => {
      setPageLoad(data);
      setSelectedTags(data);
    });
  }, [userId]);

  const handleTagToggle = (tag: string) => {
    setSelectedTags(prevTags => 
      prevTags.includes(tag)
        ? prevTags.filter(t => t !== tag)
        : prevTags.length < 10 ? [...prevTags, tag] : prevTags
    );
  };

  const navigate = useNavigate();
  
  const handleConfirm = () => {
    axios.post(`${apiUrl}/personal/user/${userId}/tags`,
    selectedTags).then(() => {
        navigate('/myprofile');
        window.location.reload();
    })
  };

  if (!pageLoad) return null;

  return (
    <div>
      <TagChoiceTop userTags={selectedTags}/>
      <TagChoiceMiddle availableTags={availableTags1} userTags={selectedTags} onTagToggle={handleTagToggle} />
      <TagChoiceMiddle availableTags={availableTags2} userTags={selectedTags} onTagToggle={handleTagToggle} />
      <TagChoiceMiddle availableTags={availableTags3} userTags={selectedTags} onTagToggle={handleTagToggle} />
      <TagChoiceMiddle availableTags={availableTags4} userTags={selectedTags} onTagToggle={handleTagToggle} />
      <TagChoiceMiddle availableTags={availableTags5} userTags={selectedTags} onTagToggle={handleTagToggle} />
      <TagChoiceBottom userTags={selectedTags} onConfirm={handleConfirm} />
    </div>
  );
};

export default OnBoardPage;
