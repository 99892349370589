import React from 'react';
import './typing-indicator.css';

const TypingIndicator = () => {
    return (
        <div className="typing">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
};

export default TypingIndicator;
