import React from "react";
import './tagsChoice.css';

interface TagChoiceMiddleProps {
  availableTags: string[];
  userTags: string[];
  onTagToggle: (tag: string) => void;
}

const TagChoiceMiddle: React.FC<TagChoiceMiddleProps> = ({ availableTags, userTags, onTagToggle }) => {
  const handleTagClick = (tag: string) => {
    onTagToggle(tag);
  };

  return (
    <div className="tag-choice-container">
      <div className="content">
        <div role="list" className="mds-row mds-gy-3 mds-gy-md-4 mds-stack-sm--24">
          {availableTags.map((tag) => (
            <div key={tag} className="mds-col-12 mds-col-sm-2 mds-col-md-2">
              <button 
                className={`mds-btn mds-btn${userTags.includes(tag) ? '--primary' : '--secondary'} mds-full-width`}
                onClick={() => handleTagClick(tag)}
              >
                <span>{tag}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TagChoiceMiddle;