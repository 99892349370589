import React, { useRef, useEffect } from 'react';
import './bubbles.css';
import TypingIndicator from '../typingIndicator/TypingIndicator';

interface Message {
    id: number;
    author: string;
    content: string;
    timestamp: string;
}

const Bubbles = ({ messages, isTyping }: { messages: Message[], isTyping: boolean }) => { // Add isTyping prop
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className='mads-scroll' ref={messagesEndRef}>
            {messages.map((message) =>
                message.author === 'User' ? (
                    <div className='mads-user' key={message.id}>
                        <div className="mads-icon">
                            <img src="https://thispersondoesnotexist.com" alt="Logo ticket-tac" />
                        </div>
                        <svg width="51" height="34" viewBox="0 0 51 34" fill="none">
                            <path d="M18.5 22.1692C11.3354 19.6282 1.5 17.2462 1.5 17.2462L33.5 1C33.5 1 32.4248 6.97345 33.5 14.7846C34.6455 23.1065 50 33 50 33C50 33 30.3317 26.3656 18.5 22.1692Z" fill="#52DFCE" stroke="#52DFCE" />
                        </svg>
                        <div className='mads-user-bubble'>
                            <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
                        </div>
                    </div>
                ) : (
                    <div className='mads-bot' key={message.id}>
                        <div className="mads-icon">
                            <img src='ticket-tac.png' alt="Logo ticket-tac" />
                        </div>
                        <svg width="51" height="35" viewBox="0 0 51 35" fill="none">
                            <path d="M32.5 23C39.6646 20.4192 49.5 18 49.5 18L17.5 1.5C17.5 1.5 18.5752 7.56679 17.5 15.5C16.3545 23.9519 1 34 1 34C1 34 20.6683 27.2619 32.5 23Z" fill="#666666" stroke="#66666" />
                        </svg>
                        <div className='mads-bot-bubble'>
                            <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
                        </div>
                    </div>
                )
            )}
            {isTyping && ( // Display typing indicator if bot is typing
                <div className='mads-bot'>
                    <div className="mads-icon">
                        <img src='ticket-tac.png' alt="Logo ticket-tac" />
                    </div>
                    <svg width="51" height="35" viewBox="0 0 51 35" fill="none">
                        <path d="M32.5 23C39.6646 20.4192 49.5 18 49.5 18L17.5 1.5C17.5 1.5 18.5752 7.56679 17.5 15.5C16.3545 23.9519 1 34 1 34C1 34 20.6683 27.2619 32.5 23Z" fill="#666666" stroke="#66666" />
                    </svg>
                    <div className='mads-bot-bubble'>
                        <TypingIndicator />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Bubbles;
