import React, { useState, useEffect } from 'react';
const backend_bot_url = process.env.REACT_APP_BACKEND_BOT_URL || 'http://localhost:80/chatbot';
export const useChatStream = (prompt: string) => {
  const [paragraphContent, setParagraphContent] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backend_bot_url}/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ message: prompt }),
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to initiate streaming');
        }
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error('Failed to get reader for response body');
        }
        const readStream = async () => {
        setParagraphContent('')  
	  while (true) {
            const { done, value } = await reader.read();
            const chunk = new TextDecoder('utf-8').decode(value);
            setParagraphContent((prevContent: string) => prevContent + chunk.replace("data: ", "").replace("\n", '').replace(".", ".\n").replace("!", "!\n").replace("?", "?\n").replace(':',':\n'));
        if (done) {
              break;
            };
	  };
    };
    readStream();
      } catch (error) {
        console.error('Error initiating streaming:', error);
      }
    };
    fetchData();
  }, [prompt]);

  return paragraphContent;
};

const ChatComponent: React.FC<{ prompt: string }> = ({ prompt }: { prompt: string }) => {
  const blip = useChatStream(prompt);

  return (
    <div>
      <p>Chatbot</p>
    </div>
  );
};

export default ChatComponent;
