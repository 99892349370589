import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

const StyledFooter = styled.footer`
    background-color: ${theme.colors.brand};
    color: white;
    display: flex;
    text-align: center;
    position: relative;
    padding: 10px;
    width: 100%;
`;
const StyledDiv = styled.div`
    padding: 50px;
    display: flex;
    flex-direction: column;
    flex: 1 1 20%;
    h3{
        text-align: left;
        color: white;
        font-size: 1em;
    }
    p{
        text-align: left;
        color: white;
        font-size: 0.8em;
    }
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            font-size: ${theme.fontSizes.small};
            padding: 5px;
            cursor: pointer;
            text-align: left;
        }
    .images{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        img{
            height: 50px;
            padding: 5px;
        
        }
    }
    span{
        color: white;
    }
    .logos{
        padding: 2%;
        margin-left: 40px;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    }
`;
const Footer = () => {
    return (
        <StyledFooter>
            <StyledDiv>
                <h3>A propos</h3>
                <ul>
                <li>Conditions générales d’utilisation</li>
                <li>Conditions générales de vente</li>
                <li>Mentions légales</li>
                <li>Protection des données personnelles</li>
                <li>Cookies et autres données de site</li>
                </ul>
                <br/>
                <br/>
                <br/>
                <br/>
                <p>© 2024 Macif - Tous droits réservés</p>
            </StyledDiv>
            <StyledDiv>
                <h3>Retrouvez-nous également tous les jours</h3>
                <ul>
                <li>Sur l'application :</li>
                <li className="images"><a href="https://apps.apple.com/us/app/macif/id946851742"><img src="/assets/AppStore.png" alt="Logo de l'Apple Store"/></a> <a href="https://play.google.com/store/apps/details?id=com.macif.mobile.application.android"><img src="/assets/PlayStore.png" alt="Logo du Play Store"/></a></li>
                <li>Avec l'extension web, partenaire de votre pouvoir d'achat au quotidien.</li>
                <li className="images"><a href="https://chromewebstore.google.com/detail/macif-avantages/oipjcohppciapfdojclipkpddkncidjo"><img src="/assets/Chrome.png" alt="Logo de Google Chrome"/></a><a href="https://extension.macifavantages.fr/download-xpi/firefox/latest"><img src="/assets/Firefox.png" alt="Logo de Firefox" /></a> <a href="https://apps.apple.com/fr/app/macif-avantages/id1460682709?mt=12"><img src="/assets/Safari.png" alt="Logo de Safari"/></a></li>
                <li>Sur nos réseaux sociaux : </li>
                <li className="logos">
                    <a href="https://www.twitter.com">
                        <span className="mds-icon__x-twitter"></span>
                    </a>
                    <a href="https://www.youtube.com">
                        <span className="mds-icon__youtube"></span>
                    </a>
                    <a href="https://www.linkedin.com">
                        <span className="mds-icon__linkedin"></span>
                    </a>
                    <a href="https://www.tiktok.com">
                        <span className="mds-icon__tiktok"></span>
                    </a>
                    <a href="https://www.facebook.com">
                        <span className="mds-icon__facebook"></span>
                    </a>
                    <a href="https://www.instagram.com">
                        <span className="mds-icon__instagram"></span> 
                    </a>
                </li>
                </ul>
            </StyledDiv>
        </StyledFooter>
    );
    }

export default Footer;