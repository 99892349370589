import React from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../theme";

const progressAnimation = (progress:number) => keyframes`
  from {
    --progress: 0;
  }
  to {
    --progress: ${progress};
  }
`;

const CircularProgress = styled.svg<{progress:number, size:number, stroke_width:number}>`
  --size: ${props => props.size +"px"};
  --half-size: calc(var(--size) / 2);
  --stroke-width: ${props => props.stroke_width +"px"};
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * 3.14159 * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: ${props => progressAnimation(props.progress)} 1s linear 0s 1 forwards;
  width: var(--size);
  height: var(--size);
  margin: 10px;
`;

const Circle = styled.circle`
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;

  &.bg {
    stroke: #ddd;
  }

  &.fg {
    transform: rotate(90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 2s linear 0s;
    stroke: ${theme.colors.brand};
  }
`;


const RoundFiller = ({ percentage, size, stroke_width }:{percentage:number, size:number, stroke_width:number}) => {
  return (
    <CircularProgress progress={percentage} size={size} stroke_width={stroke_width} viewBox="0 0 ${{size}} ${{size}}">
      <Circle className="bg"/>
      <Circle className="fg"/>
    </CircularProgress>
  );
};

export default RoundFiller;
