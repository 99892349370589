import React from "react";
import styled from "styled-components";
import { formatDistanceToNow } from 'date-fns';
import StarRating from "../StarRating/StarRating";
import { theme } from "../../theme";

interface ReviewsProps {
    stars: number;
    username: string;
    pp_url: string;
    status: string;
    review: string;
    date: Date;
}
const ReviewsContainer = styled.div`
    padding: 2%;
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin-top: 20px;
    font-family: ${theme.font};
`;

const ProfileRow = styled.div`
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 10px;
    img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    h3{
        font-size: ${theme.fontSizes.small};
        font-family: ${theme.font};
        color: black;
        font-weight: normal;
    }
    p{
        font-size: ${theme.fontSizes.xsmall};
        font-family: ${theme.font};
    }
`;

const ProfileColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2%;
`;
const StarsAndDate = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: ${theme.fontSizes.xsmall};
`


const Review : React.FC<ReviewsProps> = ({stars, pp_url, username, status, review, date}) => {
    return (
        <ReviewsContainer>
            <ProfileRow>
                <img src={pp_url} alt="profile picture"/>
                <ProfileColumn>
                    <h3>{username}</h3>
                    <p>Statut : {status}</p>
                </ProfileColumn>
            </ProfileRow>
            <br />
            <StarsAndDate>
                <StarRating rating={stars} size={20}/>
                <p>{formatDistanceToNow(date)} ago</p>
            </StarsAndDate>
            <br />
            <p>{review}</p>
        </ReviewsContainer>
    );
}

export default Review;