import React, { ChangeEvent, useState, MouseEvent, useEffect, useRef, KeyboardEvent } from 'react';
import './chatbot.css';
import Bubbles from './bubbles';
import { useChatStream } from './utils';

interface Message {
    id: number;
    author: string;
    content: string;
    timestamp: string;
}

const Chatbot = ({ messages }: { messages: Message[] | [] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanding, setIsExpanding] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(true);
    const [text, setText] = useState('');
    const [conversation, setConversation] = useState<Message[]>(messages);
    const [prompt, setPrompt] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const botResponse = useChatStream(prompt);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleMessageSend();
        }
    };

    const handleMouseEnter = () => {
        if (!isOpen) {
            setIsExpanding(true);
            setIsContentVisible(false);
            setTimeout(() => setIsContentVisible(true), 700);
        }
    };

    const handleMouseLeave = () => {
        setIsExpanding(false);
    };

    const handleOnClick = () => {
        if (!isOpen) {
            setIsContentVisible(false);
            setIsOpen(true);
            setIsExpanding(false);
            setTimeout(() => setIsContentVisible(true), 700);
        }
    };

    const handleOnClickClose = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsContentVisible(false);
        setIsOpen(false);
        setTimeout(() => setIsExpanding(false), 50);
    };

    const handleMessageSend = async () => {
        if (text.trim() === '') {
            return;
        }

        setIsLoading(true); // Start loading

        const newMessage: Message = {
            id: conversation.length + 1,
            content: text,
            author: 'User',
            timestamp: new Date().toISOString(),
        };

        setConversation(conversation => [...conversation, newMessage]);
        setText('');
        setPrompt(text);
    };

    useEffect(() => {
        if (botResponse) {
            setIsLoading(false);
            if (conversation[conversation.length - 1].author === 'Bot') {
                const botMessage: Message = {
                    id: conversation.length,
                    author: 'Bot',
                    content: botResponse.replace('data:', ''),
                    timestamp: new Date().toISOString(),
                };
                setConversation(conversation => [...conversation.slice(0, -1), botMessage]);
            } else {
                const botMessage: Message = {
                    id: conversation.length + 1,
                    author: 'Bot',
                    content: botResponse.replace('data:', ''),
                    timestamp: new Date().toISOString(),
                };
                setConversation(conversation => [...conversation, botMessage]);
            }
        }
    }, [botResponse]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [conversation]);

    return (
        <div
            className={`mads-chatbot-icon ${isExpanding ? 'expanded' : ''} ${isOpen ? 'open' : ''}`}
            onMouseEnter={handleMouseEnter}
            onClick={handleOnClick}
            onMouseLeave={handleMouseLeave}
        >
            {isExpanding ? (
                <div className="mads-expantion-bot">
                    <img src='ticket-tac.png' alt="Logo ticket-tac" />
                    {isContentVisible && (
                        <h5>Assistant</h5>
                    )}
                </div>
            ) : (
                <div>
                    {isOpen ? (
                        <div className='mads-open-bot'>
                            {isContentVisible && (
                                <>
                                    <div className='mads-top-bot'>
                                        Ticket Tac
                                        <div>
                                            <svg width="20" height="20" viewBox="0 0 20 15" fill="none">
                                                <circle cx="12" cy="12" r="4" fill="#016F50" />
                                            </svg>
                                            Connecté
                                        </div>
                                        <div onClick={handleOnClickClose}>
                                            <span className="mds-icon__close"></span>
                                        </div>
                                    </div>
                                    <div className='mads-top-line' />
                                    <div className="mads-messages-container" ref={messagesEndRef}>
                                        <Bubbles messages={conversation} isTyping={isLoading} /> {/* Pass isTyping prop */}
                                    </div>
                                    <div className='mads-bottom-line'>
                                        <textarea
                                            className="mads-resizable-textarea"
                                            value={text}
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown} // Ajout du gestionnaire d'événements
                                            rows={3}
                                        />
                                        <button className="mads-button" onClick={handleMessageSend}>
                                            <span className="mds-icon__arrow-upward"></span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <img src='ticket-tac.png' alt="Logo ticket-tac" />
                    )}
                </div>
            )}
        </div>
    );
};

export default Chatbot;
