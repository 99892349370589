import React, { createContext, useState, useEffect, ReactNode } from 'react';

type User = number;

interface UserContextType {
  currentUser: User;
  setCurrentUser: (user: User) => void;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User>(() => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? parseInt(savedUser, 10) : 2; 
  });

  useEffect(() => {
    localStorage.setItem('currentUser', currentUser.toString());
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};
