// App.tsx
import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import OnboardPage from './pages/OnboardPage';
import PersonalPage from './pages/PersonalPage';
import { UserProvider } from './components/userContext/userContext';

const backend_url = process.env.REACT_APP_API_URL || 'http://localhost:80/api';

const App: React.FC = () => {
  useEffect(() => {
    const setSessionCookie = async () => {
      try {
        const response = await axios.get(backend_url+'/session/start', {
          withCredentials: true,
        });
        console.log(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    setSessionCookie();
  }, []);

  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/product/:productId" Component={ProductPage} />
        <Route path="/onboard/" Component={OnboardPage} />
        <Route path="/myprofile/" Component={PersonalPage}/>
      </Routes>
    </Router>
    </UserProvider>
  );
}

export default App;
