import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const StarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Star = styled.span<{ size: number }>`
  font-size: ${props => props.size}px;
  color: ${theme.colors.lightGreen};
  margin-right: 5px;
`;

interface StarRatingProps {
  rating: number;
  size: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, size}) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <StarContainer>
      {Array.from({ length: fullStars }, (_, index) => (
        <Star key={`full-${index}`} className='mds-icon__star-filled' size={size}/>
      ))}
      {hasHalfStar && <Star className='mds-icon__star-half' size={size}/>}
      {Array.from({ length: emptyStars }, (_, index) => (
        <Star key={`empty-${index}`} className='mds-icon__star' size={size}/> 
      ))}
    </StarContainer>
  );
};

export default StarRating;
