import React, { useState, useContext } from 'react';
import { UserContext } from './userContext';
import styled from 'styled-components';

interface UserSelectorProps {
    show: boolean;
  }
  
const SelectorContainer = styled.div<UserSelectorProps>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction:column;
    align-items: center;
  `;

const Button = styled.button`
font-family: Montserrat;
font-size: 12px;
color: white;
width: 100px;
height: 15%;
display: flex;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
border-radius: var(--mds-space-4, 4px);
background-color: #0A2D82;
border: 0px;

&:hover {
    box-shadow: 0 5px 10px #0000001a,0 2px 4px #00000026;
    }
`;




const UserSelector: React.FC<UserSelectorProps> = ({ show }) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserSelector must be used within a UserProvider');
  }
  const { currentUser, setCurrentUser } = context;
  const [inputUser, setInputUser] = useState('');

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputUser(event.target.value);
  };

  const handleUserSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userNumber = parseInt(inputUser, 10);
    if (userNumber >= 1 && userNumber <= 2000) {
      setCurrentUser(userNumber);
      window.location.reload();
      setInputUser('');
    } else {
      alert('Veuillez entrer un numéro d\'utilisateur valide entre 1 et 2000.');
    }
  };

  return (
    <SelectorContainer show={show}>
    <form onSubmit={handleUserSubmit} style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="number"
        min="1"
        max="2000"
        value={inputUser}
        onChange={handleUserChange}
        placeholder={`user_id`}
        style={{ marginRight: '10px', padding: '5px', minWidth: '75px' }}
      />
    <Button type="submit">Changer d'utilisateur</Button>
    </form>
    <p className="mds-text--2-secondary">
        Utilisateur actuel : {currentUser}
    </p>
    </SelectorContainer>
  );
}

export default UserSelector;