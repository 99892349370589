import { DefaultTheme } from "styled-components";

const theme : DefaultTheme ={
    font: "Source Sans Pro",
    colors: {
        brand : "#0A2D82",
        lightGreen : "#2DD2C9"
    },
    fontSizes: {
        xsmall : "0.9em",
        small : "1em",
        medium : "1.4em",
        large : "1.6em",
        xlarge: "2.2em"
    },
    navbar: {
        small_font: "1em",
        medium_font: "2em",
        large_font: "3em"
    }
};

export { theme };