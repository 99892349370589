import React, { useContext, useEffect } from 'react';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import styled from 'styled-components';
import CardsSlider from '../components/cardsSlider/cardsSlider';
import Carousel from '../components/carousel/carousel';

import SmallCard from '../components/smallCards/smallCards';
import CarouselCard from '../components/cardsSlider/cards';
import axios from 'axios';
import { theme } from '../theme';
import MapComponent from '../components/map/map';
import 'leaflet/dist/leaflet.css'
import messagesData from "../components/chatbot/test_messages.json";
import Chatbot from '../components/chatbot/chatbot';
import { UserContext } from '../components/userContext/userContext';


const apiUrl = process.env.REACT_APP_API_URL;



interface CarouselItems {
  filePath: string;
  alt: string;
  link: string;
}
interface CardsSliderItems {
  image: string;
  title: string;
  initialPrice: number;
  finalPrice: number;
  soldAmount: number;
  link: string;
}
interface PageLoad {
  carouselItems: CarouselItems[];
  cardsSliderItem: CardsSliderItems[];
  smallCardsSliderItems: CardsSliderItems[];
}

async function fetchData(url: string): Promise<PageLoad> {
  const response = await axios.get(`${apiUrl}/${url}`);
  console.log(response.data);
  return response.data;
}

const StyledTitle = styled.h3`
  font-size: ${theme.fontSizes.xlarge};
  margin: 20px;
`;

const CustomUnderlineStrong = styled.strong`
  display: inline;
  background: linear-gradient(to top, rgba(50, 220, 201, 1) 40%, transparent 40%);
  color: inherit;
  text-decoration: none;
`;
const EmptyContainer = styled.div`
  height: 30px;
`;
const SmallCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: auto;
  padding: 20px;
  flex-wrap: wrap;
  row-gap: 20px;
`;
const HomePage: React.FC = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('HomePage must be used within a UserProvider');
  } 
  const userId  = context.currentUser;

  const [pageLoad, setPageLoad] = React.useState<PageLoad | null>(null);
  useEffect(() => {
    fetchData(`home/${userId}`).then((data) => setPageLoad(data));
  }, [userId]);


  if (!pageLoad) return null;

  const carouselItems = pageLoad.cardsSliderItem.map((item, index) => (
    <CarouselCard
      key={index}
      image={item.image}
      title={item.title}
      initialPrice={item.initialPrice}
      finalPrice={item.finalPrice}
      soldAmount={item.soldAmount}
      link={item.link}
    />
  ));
  const smallCards = pageLoad.smallCardsSliderItems.map((item, index) => (
    <SmallCard
      key={index}
      image={item.image}
      title={item.title}
      initialPrice={item.initialPrice}
      finalPrice={item.finalPrice}
      soldAmount={item.soldAmount}
      link={item.link}
    />
  ));
  const carouselImages : CarouselItems[] = pageLoad.carouselItems
  console.log(carouselImages)
  return (
    <div>
      <Navbar />
      <EmptyContainer />
      <Carousel images={carouselImages} />
      <StyledTitle>Notre sélection <CustomUnderlineStrong>pour vous</CustomUnderlineStrong></StyledTitle>
      <CardsSlider>
        {carouselItems}
      </CardsSlider>
      <br/>
      <StyledTitle>Meilleures <CustomUnderlineStrong>ventes</CustomUnderlineStrong></StyledTitle>
      <SmallCardsContainer>
        {smallCards}
      </ SmallCardsContainer >
      <br />
      <StyledTitle>Des activités <CustomUnderlineStrong>à proximité</CustomUnderlineStrong></StyledTitle>
      <Chatbot messages={messagesData} />
      <MapComponent />
      <Footer />
    </div>
  

  );
}

export default HomePage;
