import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import styled from 'styled-components';
import { theme } from '../theme';
import Counter from '../components/counter/counter';
import Accordion from '../components/accordion/accordion';
import Review from '../components/reviews/reviews';
import StarRating from '../components/StarRating/StarRating';
import CardsSlider from '../components/cardsSlider/cardsSlider';
import CarouselCard from '../components/cardsSlider/cards';
import { BaseObject } from 'styled-components/dist/types';
import { UserContext } from '../components/userContext/userContext';



const apiUrl = process.env.REACT_APP_API_URL;

interface BuyingOptions {
  name: string;
  price: number;
}

interface Review{
  stars: number;
  pp_url: string;
  username: string;
  status: string;
  date: Date;
  review: string;
}
interface SimilarProduct {
  image: string;
  title: string;
  initialPrice: number;
  finalPrice: number;
  soldAmount: number;
  link: string;
}
interface Product {
  id: number;
  name: string;
  description_header: string;
  description: string;
  price: number;
  price_old: number;
  image_url: string;
  conditions: string;
  howto: string;
  infopratiques: string;
  reviews: Review[];
  tags: string[];
  localisation: string;
}

interface History {
  product_id: number;
  time: Date;
}

async function fetchData(url: string): Promise<Product> {
  const response = await axios.get(`${apiUrl}/${url}`);
  console.log(response.data);
  const product: History = { product_id: response.data.id, time: new Date() };
  addViewedProduct(product);
  return response.data;
}

async function fetchRecommandation(viewedProducts: History[]): Promise<SimilarProduct[]> {
  const response = await axios.post(`${apiUrl}/reco`, { viewedProducts });
  console.log(`URL ADRESS: ${apiUrl}/reco`)
  console.log(`LA REPONSE RECUE EST : ${JSON.stringify(response.data)}`);
  console.log(`EXTRACTED DATA: ${JSON.stringify(response.data.similar_products)}`)
  return Array.isArray(response.data.similar_products) ? response.data.similar_products : [];
}

function addViewedProduct(history: History) {
  let viewedProducts: History[] = JSON.parse(sessionStorage.getItem('viewedProducts') || '[]');
  viewedProducts = viewedProducts.filter(product => product.product_id !== history.product_id);
  viewedProducts.push(history);
  sessionStorage.setItem('viewedProducts', JSON.stringify(viewedProducts));
}

const calculateAverageRating = (reviews: Review[]): number => {
  if (reviews.length === 0) return 0;
  const total = reviews.reduce((sum, review) => sum + review.stars, 0);
  return total / reviews.length;
}

const calculateNumberOfReviews = (reviews: Review[]): number => {
  return reviews.length;
}

const StyledMainDiv = styled.div`
    font-family: ${theme.font};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5%;
    gap: 5%;
    padding-top: 0;
    h1{
        color: ${theme.colors.brand};
    }
    p{
        font-size: ${theme.fontSizes.xsmall};
        color: black;
        white-space: pre-wrap;
    }
    img{
        width: 50%;
        border-radius: 20px;
    }
    s{
      text-decoration: none;
      position: relative;
    }
    
    s::before{
        content: '';
        width: 100%;
        position: absolute;
        right: 0;
        top: calc( 50% - 1px );
        border-bottom: 0.15rem solid red;
    }
    strong{
      color: ${theme.colors.lightGreen};
    }
`;
const StyledTitle = styled.h1`
    color: ${theme.colors.brand};
    padding: 1%;
    padding-bottom: 0.3%;
    padding-left: 3%;
    font-size: ${theme.fontSizes.large};
`;
const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    h2{
      font-size: ${theme.fontSizes.medium};
    }
    h3{
      font-size: ${theme.fontSizes.small};
      font-weight: bold;
      color: black;
    }
`;
const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    h2{
      color: ${theme.colors.lightGreen};
    }
    align-items: center;
    justify-content: space-between;
`;
const StyledHeart = styled.span`
    font-size: ${theme.fontSizes.xlarge};
    transition: color 0.3s
    padding-right: 15%;
    margin-bottom: -3%;
    .mds-icon__favorite-filled {
        color: grey; /* Default color */
        transition: color 0.3s;
      }
    .mds-icon__favorite-filled.liked {
    color: ${theme.colors.brand}; /* Color when liked */
    }
`;
const StyledButton = styled.button`
    font-size: ${theme.fontSizes.small};
    color: white;
    background-color: ${theme.colors.brand};
    border: none;
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
    &:hover{
        background-color: ${theme.colors.lightGreen};
    }
`;
const ReviewContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const SectionTitle = styled.h3`
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.brand};
    padding-top: 2%;
    padding-left: 2%;
    padding-bottom: 0;
`;
const StarsAndReviews = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    padding-left: 3%;
    padding-bottom: 1%;
    gap: 1.5%;
    a{
      transform: translateY(2px);
    }
    h4{
      font-size: ${theme.fontSizes.medium};
      color: black;
    }
    `;
const TagsContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 3%;
    padding-bottom: 2%;
    padding-top: 0;
`;
const TitleToTags = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1%;
    padding-left: 3%;
`;
const DownSlidingArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 5%;
    padding-top: 2%;
    a{
      text-decoration: none;
    }
`;
const ProductPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [numberOfReviews, setNumberOfReviews] = useState<number>(0);
  const [recommendation, setRecommendation] = useState<SimilarProduct[]>([]);
  const [liked, setLiked] = useState<boolean>(false);

  const context = useContext(UserContext);
    if (!context) {
    throw new Error('HomePage must be used within a UserProvider');
  }
  const userId = context.currentUser;

  useEffect(() => {
    if (productId) {
      // const formattedName = name.replace(/ /g, "_");
      fetchData(`product/${productId}`).then(data => {
        setProduct(data);
        setAverageRating(calculateAverageRating(data.reviews));
        setNumberOfReviews(calculateNumberOfReviews(data.reviews));
      console.log("data"+data);
      });
    }
  }, [productId]);

  useEffect(() => {
    const fetchLikeStatus = async () => {
      try {
        const response = await axios.get(`${apiUrl}/product/${productId}/like-status/${userId}`);
        setLiked(response.data);
        console.log("fetch like status")
        console.log(`like status: ${response.data}`)
      } catch (error) {
        console.error('Erreur lors de la vérification du statut du like', error);
      }
    };

    fetchLikeStatus();
  }, [productId]);

  const handleLikeToggle = async () => {
    try {
      if (liked) {
        await axios.post(`${apiUrl}/product/${productId}/unlike/${userId}`);
        console.log("unlike product")
      } else {
        await axios.post(`${apiUrl}/product/${productId}/like/${userId}`);
        console.log("like product")
      }
      setLiked(!liked);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du like', error);
    }
  };

  useEffect(() => {
    if (product) {
      const viewedProducts: History[] = JSON.parse(sessionStorage.getItem('viewedProducts') || '[]');
      fetchRecommandation(viewedProducts).then(data => {
        setRecommendation(data);
      });
    }
  }, [product]);

  console.log("after " + product);

  if (!product) {
    return <div>Loading...</div>;
  }

  const carouselItems = (recommendation ?? []).map((item, index) => (
    <CarouselCard
      key={index}
      image={item.image}
      title={item.title}
      initialPrice={item.initialPrice}
      finalPrice={item.finalPrice}
      soldAmount={item.soldAmount}
      link={item.link}
    />
  ));

  return (
    <>
      <Navbar />
      <TitleToTags>
      <StyledTitle>{product.name}</StyledTitle>
      <StarsAndReviews>
      <StarRating rating={averageRating} size={20}/>
      <a href='#reviews'>{numberOfReviews} avis</a>
      </StarsAndReviews>
      <TagsContainer>
        {product.tags.map((tag, index) => (
          <p className="mds-badge mds-badge--big mds-badge--neutral"><span>{tag}</span></p>
        ))}
        <p className="mds-badge mds-badge--big mds-badge--success mds-icon__location-on"><span>{product.localisation}</span></p>
      </TagsContainer>
      </TitleToTags>
      <StyledMainDiv>
      <img src={product.image_url} alt="Image Depicting the product" />
      <StyledColumn>
      <h2>Description</h2>
      <h3>{product.description_header}</h3>
      <p>{product.description}</p>
      <StyledRow>
      <StyledColumn>
      <br/>
      <h3>Prix Public: <s>{product.price_old} €</s></h3>
      <h3>Votre Prix: <strong>{product.price} €</strong></h3>
      </StyledColumn>
      <StyledHeart style={{
        color: (liked) ? theme.colors.brand : 'grey',
        cursor: 'pointer'
      }} onClick={handleLikeToggle} className='mds-icon__favorite-filled'></StyledHeart>
      </StyledRow>
      <Counter />
      <br/>
      <StyledButton>Ajouter au panier</StyledButton>
      <DownSlidingArrow>
        <span className='mds-icon__arrow-downward'></span>
        <a href='#en_savoir_plus'><h3>En savoir plus</h3></a>
        <span className='mds-icon__arrow-downward'></span>
      </DownSlidingArrow>
      </StyledColumn>
      </StyledMainDiv>
      <div id='en_savoir_plus'>
      <Accordion title="Conditions de l'offre" children={product.conditions} />
      <Accordion title="Comment récupérer votre billet ?" children={product.howto} />
      <Accordion title="Informations pratiques ?" children={product.infopratiques} />
      </div>
      <SectionTitle id='reviews'>Avis des utilisateurs :</SectionTitle>
      <ReviewContainer>
        {product.reviews.map((review, index) => (
          <Review key={index} {...review} />
        ))}
      </ReviewContainer>
      <SectionTitle>Vous aimerez également :</SectionTitle>
      <div style={{padding: "1%"}}>
      <CardsSlider>
        {carouselItems}
      </CardsSlider>
      </div>
--
      <Footer />
    </>
  );
}

export default ProductPage;
