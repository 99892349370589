import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const AccordionSection = styled.div`
  font-family: ${theme.font};
  width: 100%;
`;

const AccordionTitle = styled.div`
  cursor: pointer;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: white;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${theme.fontSizes.small};

  &:hover {
    background-color: #E5E4E2;
  }
`;

const AccordionContent = styled.div<{ isopen: boolean }>`
  max-height: ${({ isopen }) => (isopen ? '200px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: ${({ isopen }) => (isopen ? '1%' : '0')};
  margin-left: 2%;
  font-size: ${theme.fontSizes.xsmall};
`;

const AccordionIcon = styled.span<{ isopen: boolean }>`
  display: inline-block;
  font-size: ${theme.fontSizes.medium};
  transform: rotate(${({ isopen }) => (isopen ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

interface AccordionProps {
  title: string;
  children: string
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isopen, setisopen] = useState<boolean>(false);

  return (
    <AccordionSection>
      <AccordionTitle onClick={() => setisopen(!isopen)}>
        {title}
        <AccordionIcon isopen={isopen} className='mds-icon__expand-more'></AccordionIcon>
      </AccordionTitle>
      <AccordionContent isopen={isopen}>
        {children}
      </AccordionContent>
    </AccordionSection>
  );
};

export default Accordion;
