import React from "react";
import './CardProductLarge.css'





const CardProductLarge = ({ image, title, initialPrice, finalPrice, soldAmount, link}:{
    image :string, title: string, initialPrice: number, finalPrice:number, soldAmount:number, link:string}) => {
    return (
        <div className="mds-col-12 mds-col-md-4">
            <div className="mds-card mds-card--basic-1 mds-card--default">
                <div className="mds-card__content">
                    <h6 className="mads-card__title"><a className="mds-card__link" href={link}>{title}</a></h6>
                </div>
                <div className="mads-card__img">
                    <img src={image} alt=""/>
                 </div>
                 <div className="mds-card__content">
                    <div className="mads-card__prices">
                        <div className="mads-card__initial-price ">
                        <h5>{initialPrice}€</h5>
                        </div>
                        <div className="mads-card__final-price ">
                            {finalPrice}€
                        </div>
                    </div>
                </div>
                <div className="mds-card__content">
                <div className="mads-card__sold-amount">
                    {soldAmount} vendus
                </div>
                </div>
            </div>
        </div>
    );
};

export default CardProductLarge;