import React from "react";
import './tagsChoice.css';

interface TagChoiceBottomProps {
    userTags: string[];
    onConfirm: (selectedTags: string[]) => void;
  }
  
  const TagChoiceBottom: React.FC<TagChoiceBottomProps> = ({ userTags, onConfirm }) => {
    
    const selectedTags = userTags;
  
  
    const handleConfirm = () => {
      onConfirm(selectedTags);
    };
   
    return (
        <div className="tag-choice-container">
        <button className="mds-btn mds-btn--primary  mds-full-width--sm" onClick={handleConfirm} /* disabled={selectedTags.length === 0 }*/>
          Confirmer
        </button>
        <p>
        <span style={{ display: 'block', height: '4em' }}></span>
        </p>
        </div>
    );
  };
  

export default TagChoiceBottom;