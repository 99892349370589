import React, { useRef, ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0px 5px 5px 0.2px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #F2F2F2;
  }

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
`;

const CarouselTrack = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`;

const CarouselItem = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${theme.fontSizes.medium};
`;

const Arrow = styled.span`
  font-size: 24px;
  color: ${theme.colors.brand};
  transform: translateY(2px);
`;

interface CarouselProps {
  children: ReactNode;
}

const CardsSlider: React.FC<CarouselProps> = ({ children }) => {
  const trackRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (trackRef.current) {
      trackRef.current.scrollBy({ left: -1000, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (trackRef.current) {
      trackRef.current.scrollBy({ left: 1000, behavior: 'smooth' });
    }
  };

  return (
    <CarouselContainer>
      <ArrowButton className="left" onClick={scrollLeft}>
        <Arrow className='mds-icon__arrow-back'></Arrow>
      </ArrowButton>
      <CarouselTrack ref={trackRef}>
        {React.Children.map(children, (child, index) => (
          <CarouselItem key={index}>{child}</CarouselItem>
        ))}
      </CarouselTrack>
      <ArrowButton className="right" onClick={scrollRight}>
        <Arrow className='mds-icon__arrow-forward'></Arrow>
      </ArrowButton>
    </CarouselContainer>
  );
};

export default CardsSlider;
