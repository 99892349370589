import React from "react";
import './tagsChoice.css';

interface TagChoiceTopProps {
    userTags: string[];
  }
  
  const TagChoiceTop: React.FC<TagChoiceTopProps> = ({ userTags }) => {
    
    const selectedTags = userTags;
  
   
    return (
        <div className="tag-choice-container">
            <div className="wave wave-top"></div>
            <div className="wave wave-middle wave-middle-0"></div>
            <div className="wave wave-middle wave-middle-1"></div>
            <div className="wave wave-middle wave-middle-2"></div>
            <div className="wave wave-middle wave-middle-3"></div>
            <div className="wave wave-bottom"></div>
        <div className="content">
        <h1 className="tag-choice-title">Bonjour et bienvenue sur <span className="mds-underline--color-2100">Macif+</span> </h1>
         <h2>Quel type d'activité recherchez-vous ? Sélectionnez <span className="mds-underline--color-2100">jusqu'à 10</span> catégories :</h2> 
        <h3 className="tag-choice-subtitle">Catégories sélectionnées : {selectedTags.length}/10</h3>

        </div>
      </div>
    );
  };
  

export default TagChoiceTop;