import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

const CardContainer = styled.div`
    padding-left: 1%;
    font-family: ${theme.font};
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 400px;
    justify-content: space-around;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: black;
    font-size: 10px;
    drop-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    a{
        text-decoration: none;
    }
`;

const ImageContainer = styled.div`
    flex: 0 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CarouselImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 3%;
`;

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5%;
    height: 100%;
`;

const PricesContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`;

const StyledTitle = styled.h1`
    color: ${theme.colors.brand};
    padding: 5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: ${theme.fontSizes.medium};
    text-align: center;
    line-height: 1.5;
`;

const Styledh3 = styled.h3`
    font-size: 1.5em;
    color: ${theme.colors.lightGreen};
`;

const Strikethrough = styled(Styledh3)`
    color: ${theme.colors.brand};
    background: linear-gradient(to left top, transparent 47.75%, currentColor 48%, currentColor 52%, transparent 52.25%);
`;


const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

interface CardProps {
    image: string;
    title: string;
    initialPrice: number;
    finalPrice: number;
    soldAmount: number;
    link: string;
}

const SmallCard: React.FC<CardProps> = ({ image, title, initialPrice, finalPrice, soldAmount, link }) => {
    return (
        <a href={link} style={{ textDecoration: "none" }}>
            <CardContainer>
                <ImageContainer>
                    <CarouselImage src={image} alt={title} />
                </ImageContainer>
                <ContentContainer>
                    <StyledColumn>
                        <StyledTitle>{title}</StyledTitle>
                        <PricesContainer>
                            <Strikethrough>{initialPrice} €</Strikethrough>
                            <Styledh3>{finalPrice} €</Styledh3>
                        </PricesContainer>
                    </StyledColumn>
                </ContentContainer>
            </CardContainer>
        </a>
    );
};

export default SmallCard;
