import React, { useState } from "react";
import styled from "styled-components";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { theme } from "../../theme";
import DropdownMenu from "../dropdown/dropdown";
import UserSelector from "../userContext/userSelector";

const NavbarContainer = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${theme.navbar.medium_font};
    margin: 0;
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 4%;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    height: 75px;
    color: ${theme.colors.brand};
`;

const TitleContainer = styled.div<{ show: boolean }>`
    display: ${props => (props.show ? 'flex' : 'none')};
    align-items: center;
    justify-content: space-between;
    width: 65%;
    font-size: .5em;
`;

const IconsContainer = styled.div<{ show: boolean} >`
    display: ${props => (props.show ? 'flex' : 'none')};
    align-items: center;
    justify-content: space-around;
    width: 15%;
`;

const Logo = styled.img`
    height: 30px;
`;

const IconsStyle = styled.span`
    font-size: inherit;
    color: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -40px;
`;

const StyledLink = styled.a`
    font-size: 1em;
    color: red;
    padding: 20px 20px;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #F0F0F0;
    }
`;

const SearchContainer = styled.div<{ expanded: boolean }>`
    position: relative;
    width: ${props => (props.expanded ? '90%' : '30px')};
    display: flex;
    align-items: center;
    transition: width 0.4s ease-in-out;
    border: 1px solid transparent;
    margin-right: ${props => (props.expanded ? "0": "-100px")};
    &:hover {
        
    }

    &:hover input {
        border: 1px solid #ccc;
        border-radius: 15px;
        opacity: 1;
        padding-right: 30px;
    }
`;

const SearchInput = styled.input<{ expanded: boolean }>`
    width: ${props => (props.expanded ? '100%' : '0')};
    height: 30px;
    padding: 5px;
    border: none;
    outline: none;
    opacity: ${props => (props.expanded ? '1' : '1')};
    transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
`;

const CrossHide = styled.span<{ show: boolean }>`
    display: ${props => (props.show ? 'flex' : 'none')};
    padding-left: 15px;
`;
const GeneralWrapper = styled.div`
    color: inherit;
    font-size: inherit;
`;

const Navbar = () => {
    document.documentElement.style.setProperty('--color-use--9', theme.colors.brand);
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <GeneralWrapper onMouseLeave={() => setIsExpanded(false)}>
        <NavbarContainer>
            <a href="/"><Logo src="/assets/logo_macif+.png" /></a>

            <TitleContainer show={!isExpanded}>
            <UserSelector show={!isExpanded}/>
                <StyledLink href="">Parcs et loisirs</StyledLink>
                <StyledLink href="">Cinémas</StyledLink>
                <StyledLink href="">Spectacles</StyledLink>
                <StyledLink href="">Loisirs</StyledLink>
                <StyledLink href="">Sorties</StyledLink>
                <StyledLink href="">Sports</StyledLink>
            </TitleContainer>
            
            <SearchContainer
                expanded={isExpanded}
                onMouseOver={() => setIsExpanded(true)}

            >
                <IconsStyle className="mds-icon__search"  style={{ transform: 'translateY(1px)' }}/>
                <SearchInput type="text" placeholder="Search..." expanded={isExpanded} />
                <CrossHide show={isExpanded} className="mds-icon__close" onClick={() => setIsExpanded(false)}/>
            </SearchContainer>

                <IconsContainer show={!isExpanded}>
                <ShoppingCartOutlinedIcon fontSize="inherit" color="inherit" style={{ transform: 'translateY(-1px)'}} />
                    <a href="/myprofile/" style={{textDecorationLine: undefined}}><IconsStyle className="mds-icon__account-circle" /></a>
                </IconsContainer>
        </NavbarContainer>
        < DropdownMenu isVisible={isExpanded} />
        </GeneralWrapper>
    );
};

export default Navbar;