import { useState } from "react";


const Carousel = ({ images }: { images: {filePath: string, alt: string, link: string}[] }) => {
    const n = images.length
    const [slideIndex, setSlideIndex] = useState<number>(0)
    const [translationX, setTranslationX] = useState<number>(0)

    const slides = images.map(({filePath, alt, link}, index) => {
        let slideVisibility: "hidden" | "visible"  = "hidden"
        if (slideIndex === index) {
            slideVisibility = "visible"
        }
        return (
            <div className="mds-carousel__slide" style={{visibility: slideVisibility}} key={filePath} tabIndex={-1}>
                <a href={link}>
                    <img src={filePath} alt={alt} />
                </a>
            </div>
        )
    })

    

    const nextSlide = () => {
        const newIndex = slideIndex + 1
        setSlideIndex(newIndex)
        setTranslationX(-newIndex * 100)
    }

    const prevSlide = () => {
        const newIndex = slideIndex - 1
        setSlideIndex(newIndex)
        setTranslationX(-newIndex * 100)
    }

    const goSlide = (n: number) => {
        setSlideIndex(n)
        setTranslationX(-n * 100)
    }

    const carouselIndicators = images.map((value, index) => {
        return(
            <li className="mds-carousel__indicator-item">
                <button className={(index === slideIndex) ? "mds-carousel__indicator active" : "mds-carousel__indicator"}
                onClick={() => goSlide(index)}
                key={index}>
                    <span className="mds-sr-only">Aller au panneau {index + 1}</span>
                    <span className="mds-indicator"></span>
                </button>
            </li>
        )
    })

    const carouselNav = (
        <div>
        <div className="mds-carousel__navigation">
          <button className="mds-carousel__btn--prev mds-icon__chevron-left" 
          disabled={slideIndex === 0}
          onClick={prevSlide}>
            <span className="mds-sr-only">Panneau précédent</span></button>
          <ul className="mds-carousel__indicators">
                {carouselIndicators}
          </ul>
          <button className="mds-carousel__btn--next mds-icon__chevron-right"
          disabled={slideIndex === n-1}
          onClick={nextSlide}>
            <span className="mds-sr-only">Panneau suivant</span></button>
        </div>
      </div>
    )

    return (

<section id="carousel-id" aria-roledescription="carousel" aria-label="label-id">
  <div className="mds-carousel--standard">
    <div className="mds-carousel__container">
      <div className="mds-carousel__track" style={{transform: `translateX(${translationX}%)`}}>

        {slides}
    
      </div>

      {carouselNav}

    </div>
  </div>
</section>
    );
};

export default Carousel;