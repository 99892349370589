import React , {useEffect} from "react";
import styled from "styled-components";
import CarouselCards from "../cardsSlider/cards";
import { theme } from "../../theme";

const DropdownContainer = styled.div<{show: boolean}>`
    display: ${props => (props.show ? 'flex' : 'none')};
    position: relative;
    margin-top: 10px;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: all 0.5s ease;
`;

const TextContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin-left: 5%;
    flex: 1 1 20%;
    h1{
        font-size: 1em;
    }
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            font-size: ${theme.fontSizes.small};
            padding: 5px;
            cursor: pointer;
        }
    }
`;

const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1 1 90%;
    justify-content: space-around;
`;

type DropdownMenuProps = {
    isVisible: boolean;
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({ isVisible, ...props }) => {    
    return (
        <DropdownContainer show={isVisible}>
            <TextContainer>
                <h1>Recherches fréquentes</h1>
                <ul>
                    <li>Parc Astérix</li>
                    <li>Vulcania</li>
                    <li>Le Pal</li>
                </ul>
            </TextContainer>
            <CardsContainer>
                <CarouselCards  image="https://upload.wikimedia.org/wikipedia/fr/c/c0/Logo_Parc_Astérix_2020.png?20200130223814" 
                    title="Parc astérix - billet" 
                    initialPrice = {64.00}  
                    finalPrice= {51.00}
                    soldAmount= {1204} 
                    link = "https://upload.wikimedia.org/wikipedia/fr/c/c0/Logo_Parc_Astérix_2020.png?20200130223814"/>
                <CarouselCards  image="https://upload.wikimedia.org/wikipedia/fr/c/c0/Logo_Parc_Astérix_2020.png?20200130223814" 
                    title="Parc astérix - billet" 
                    initialPrice = {64.00}  
                    finalPrice= {51.00}
                    soldAmount= {1204} 
                    link = "https://upload.wikimedia.org/wikipedia/fr/c/c0/Logo_Parc_Astérix_2020.png?20200130223814"/>
                <CarouselCards  image="https://upload.wikimedia.org/wikipedia/fr/c/c0/Logo_Parc_Astérix_2020.png?20200130223814" 
                    title="Parc astérix - billet" 
                    initialPrice = {64.00}  
                    finalPrice= {51.00}
                    soldAmount= {1204} 
                    link = "https://upload.wikimedia.org/wikipedia/fr/c/c0/Logo_Parc_Astérix_2020.png?20200130223814"/>
            </CardsContainer>
        </DropdownContainer>
    );
}
export default DropdownMenu;