
import { useContext, useEffect } from 'react';
import Navbar from '../components/navbar/navbar';
import PersonalFrame from '../components/personalFrame/personalFrame';
import axios from 'axios';
import React from 'react';
import { UserContext } from '../components/userContext/userContext';


const apiUrl = process.env.REACT_APP_API_URL;


interface Product {
  image: string;
  title: string;
  initialPrice: number;
  finalPrice: number;
  soldAmount: number;
  link: string;
}

interface Info {
  firstName: string;
  lastName: string;
  ppUrl: string;
}


interface PageLoad {
  likes: Product[];
  orders: Product[];
  info: Info[];
  tags: String[];
}

async function fetchData(url: string) {
  const response = await axios.get(`${apiUrl}/${url}`);
  console.log(response.data);
  return response.data;
}

const PersonalPage: React.FC = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('HomePage must be used within a UserProvider');
  } 
  const userId  = context.currentUser;

  const [pageLoad, setPageLoad] = React.useState<PageLoad | null>(null);
  useEffect(() => {
    fetchData(`personal/${userId}`).then((data) => setPageLoad(data));
  }, [userId]);


  if (!pageLoad) return null;
    return (
    <div>
        <Navbar/>
        <PersonalFrame data = {pageLoad}/>
      </div>
    );
  };
  
  export default PersonalPage;