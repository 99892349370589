import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import './map.css';
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

interface Product {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  link: string;
}

async function fetchProductLocations(): Promise<Product[]> {
    const response = await axios.get(`${apiUrl}/map`)
    return response.data
}

const MapComponent = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [position, setPosition] = useState<[number, number]>([48.8566, 2.3522]); // (Paris)


  useEffect(() => {
    // Actual position of user
    navigator.geolocation.getCurrentPosition((position) => {
      setPosition([position.coords.latitude, position.coords.longitude]);
    //Can use the search param  
    });

    // Get closerst products
    fetchProductLocations().then((productLocations): void => {
        setProducts(productLocations)
    });
    }, []);


  return ( 
    <div className="mds-row">
    <div className="mds-col-md-8 mds-inline--auto">
    <div className="top-bar">
        Proche de vous
    </div>
    <MapContainer center={position} zoom={12} style={{ height: "60vh", width: "100%" , borderRadius: "0px 0px 4px4px"} }>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {products.map(product => (
        <Marker 
          key={product.id} 
          position={[product.latitude, product.longitude]}
          icon={new L.Icon({
            iconUrl: 'assets/location_on.png',
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [0, -34]
          })}
        >
          <Popup>
            <h6>{product.name}</h6>
            <p className="mds-text--4">{product.address}</p>
            <a href={product.link}>
                <button className="mds-btn mds-btn--primary mds-btn--small">
                    <span>Voir le produit</span>
                </button>
            </a>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
    </div>
    </div>
    );
};

export default MapComponent;
